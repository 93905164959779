<div class="wrapper">
  <div class="container-fluid">
    <div class="row mb-4">
      <div class="col-xl-12">
        <div class="card">
          <div class="card-body">
            <h2 class="card-title">{{ greeting }}, {{ userName }}!</h2>
            <p class="card-text">What would you like to do today?</p>
          </div>
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col-xl-12">
        <div class="card-box">

          <ul class="nav nav-tabs">
            <li class="nav-item">
              <a href="#home" data-toggle="tab" aria-expanded="false" class="nav-link active">
                Recent Apps
              </a>
            </li>
            <li class="nav-item">
              <a href="#profile" data-toggle="tab" aria-expanded="true" class="nav-link ">
                My Notification
              </a>
            </li>
          </ul>
          <div class="tab-content" style=" background: #e8e8e8; padding: 10px;">
            <div class="tab-pane show active" id="home">
              <div class="row">
                <div class="col-md-12">
                  <div class="menu-grid">

                    <div class="menu-item" *ngIf="viewCustMgt" routerLink="/app/customers">
                      <div class="avatar-lg rounded-circle bg-soft-primary border-primary border">
                        <i class="bi bi-people font-22 avatar-title text-primary"></i>
                      </div>

                      <span>Customer Management</span>
                    </div>


                    <div class="menu-item" *ngIf="viewProviderMgt" routerLink="/app/providers">
                      <div class="avatar-lg rounded-circle bg-soft-success border-success border">
                        <i class="bi bi-hospital font-22 avatar-title text-success"></i>
                      </div>

                      <span>Provider Management</span>
                    </div>

                    <div class="menu-item" *ngIf="viewClaimsMgt" routerLink="/app/claims">
                      <div class="avatar-lg rounded-circle bg-soft-info border-info border">
                        <i class="bi bi-file-earmark-text font-22 avatar-title text-info"></i>
                      </div>

                      <span>Claims <br /> Management</span>
                    </div>

                    <div class="menu-item" *ngIf="viewUnderW" routerLink="/app/underwriting">
                      <div class="avatar-lg rounded-circle bg-soft-warning border-warning border">
                        <i class="bi bi-pencil-square font-22 avatar-title text-warning"></i>
                      </div>

                      <span>Underwriting</span>
                    </div>

                    <div class="menu-item" *ngIf="viewPolicyMgt" routerLink="/app/policy">
                      <div class="avatar-lg rounded-circle bg-soft-warning border-warning border">
                        <i class="bi bi-file-earmark-text font-22 avatar-title text-warning"></i>
                      </div>

                      <span>Policy Management</span>
                    </div>

                    <div class="menu-item" *ngIf="viewMicroIns" routerLink="/app/micro">
                      <div class="avatar-lg rounded-circle bg-soft-warning border-warning border">
                        <i class="bi bi-shield-check font-22 avatar-title text-warning"></i>
                      </div>

                      <span>Micro-Insurance</span>
                    </div>

                    <div class="menu-item" *ngIf="viewAccess" routerLink="/app/access">
                      <div class="avatar-lg rounded-circle bg-soft-warning border-warning border">
                        <i class="bi bi-key font-22 avatar-title text-warning"></i>
                      </div>

                      <span>Access89</span>
                    </div>

                    <div class="menu-item" *ngIf="viewMcare" routerLink="/app/mcare">
                      <div class="avatar-lg rounded-circle bg-soft-warning border-warning border">
                        <i class="bi bi-heart-pulse font-22 avatar-title text-warning"></i>
                      </div>

                      <span>mCare</span>
                    </div>



                  </div>
                </div>

              </div>

              <div class="row">

              </div>

            </div>
            <div class="tab-pane" id="profile">
              <div class="row">
                <div class="col-md-12">
                  <div class="table-responsive">
                    <table class="table" *ngIf="allNotifs.length !== 0">
                      <thead>
                        <tr>
                          <th>Title</th>
                          <th>Details</th>
                          <th>Date</th>
                          <th>Action</th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr *ngFor="let info of allNotifs">
                          <td>{{info.notificationsubject}} <span class="badge badge-info"
                            *ngIf="info.notificationstatus === 'unread'">Unread</span></td>
                          <td [innerHTML]="info.notificationbody"></td>
                          <td>{{ timeAgo(info.datecreated) }}</td>
                          <td *ngIf="info.notificationstatus === 'unread'">
                            <button class="btn btn-sm btn-primary" (click)="markAsRead(info.id)"> Mark as read</button>
                          </td>
                        </tr>
                      </tbody>
                    </table>
                    <h5 class="text-center" *ngIf="allNotifs?.length === 0">You have no notifications</h5>
                    <h5 class="text-center" *ngIf="loadAllNotifs">Loading...<i class="bi bi-arrow-repeat spin"></i>
                    </h5>
                  </div>

                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

    </div>

  </div>
</div>
