import { Component, OnInit } from "@angular/core";
import { MenuService } from "../../services/menu.service";
import { Notifications } from "src/app/models/notifications";
import { Role } from "src/app/models/role";
import * as moment from "moment";
import { CheckUserAPIResponse } from "src/app/models/http-response";

@Component({
  selector: "app-menu",
  templateUrl: "./menu.component.html",
  styleUrls: ["./menu.component.scss"],
})
export class MenuComponent implements OnInit {
  constructor(private menuService: MenuService) {}
  allNotifs: Notifications[] = [];
  roles: Role[] = [];
  viewCustMgt = false;
  viewProviderMgt = false;
  viewClaimsMgt = false;
  viewUnderW = false;
  viewPolicyMgt = false;
  viewMicroIns = false;
  viewAccess = false;
  viewMcare = false;
  loadAllNotifs = false;
  greeting = "";
  userName = "";

  ngOnInit() {
    this.getAllNotifications();
    this.setGreeting();
    this.getUserName();

    this.menuService.checkuser().subscribe((data: CheckUserAPIResponse) => {
      this.roles = JSON.parse(data.roles);
      for (let i = 0; i < this.roles.length; i++) {
        this.sortMenuOptions(this.roles[i]);
      }
    });
  }

  setGreeting() {
    const currentHour = new Date().getHours();
    if (currentHour < 12) {
      this.greeting = "Good morning";
    } else if (currentHour < 18) {
      this.greeting = "Good afternoon";
    } else {
      this.greeting = "Good evening";
    }
  }

  getUserName() {
    this.userName = localStorage.getItem("name") || "User";
  }

  getAllNotifications() {
    this.loadAllNotifs = true;
    this.allNotifs = [];
    this.menuService.getAllNotifs().subscribe((data: Notifications[]) => {
      if (data) {
        this.allNotifs = data;
      }
      this.loadAllNotifs = false;
    });
  }

  timeAgo(dateStr: string): string {
    const now = moment();
    const date = moment(dateStr);
    const diffInMinutes = now.diff(date, "minutes");

    if (diffInMinutes < 60) {
      return `${diffInMinutes} mins ago`;
    } else if (diffInMinutes < 1440) {
      const diffInHours = now.diff(date, "hours");
      return `${diffInHours} hours ago`;
    } else {
      return date.format("YYYY-MM-DD");
    }
  }

  markAsRead(nid: string) {
    this.menuService.readNotif(nid).subscribe(() => {
      this.getAllNotifications();
    });
  }

  sortMenuOptions(role: Role) {
    switch (role.roleid) {
      case "uor":
        this.viewUnderW = true;
        break;
      case "ura":
        this.viewUnderW = true;
        break;
      case "uu":
        this.viewUnderW = true;
        break;
      case "umu":
        this.viewUnderW = true;
        break;
      case "upc":
        this.viewUnderW = true;
        break;
      case "ccp":
        this.viewClaimsMgt = true;
        break;
      case "cpa":
        this.viewClaimsMgt = true;
        break;
      case "cch":
        this.viewClaimsMgt = true;
        break;
      case "cpah":
        this.viewClaimsMgt = true;
        break;
      case "cmci":
        this.viewCustMgt = true;
        break;
      case "pap":
        this.viewProviderMgt = true;
        break;
      case "ppa":
        this.viewProviderMgt = true;
        break;
      case "pinv":
        this.viewPolicyMgt = true;
        break;
      case "pqut":
        this.viewPolicyMgt = true;
        break;
      case "mis":
        this.viewMicroIns = true;
        break;
      case "mimu":
        this.viewMicroIns = true;
        break;
      case "mimci":
        this.viewMicroIns = true;
        break;
      case "mir":
        this.viewMicroIns = true;
        break;
      case "apc":
        this.viewAccess = true;
        break;
      case "mcarec":
        this.viewMcare = true;
        break;
      case "mcarea":
        this.viewMcare = true;
        break;
      case "mcarema":
        this.viewMcare = true;
        break;
      case "mcarer":
        this.viewMcare = true;
        break;
    }
  }
}
